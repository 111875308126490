<template>
  <van-tabs v-model="activeTabIndex" color="#2DD070" :before-change="beforeChange">
    <van-tab :title='$t("stringCode.ForWithdrawal")'>
      <ul class="list" v-if="withDrawList.length !== 0">
        <li
          class="item"
          @click="chooseList(item.recordNo)"
          v-for="(item, index) in withDrawList"
          :key="item.id"
        >
          <p class="code">
            <span class="number">{{ item.codeNo }}</span>
            <span class="money">{{ item.points }}</span>
          </p>
          <p>{{ item.skuName }}</p>
          <p>{{ item.createTime }}</p>
        </li>
        <p class="nomore" v-if="withDrawPage > withDrawTotalPage">{{ $t("mine.nomore") }}</p>
        <div v-if="isWithDrawLoading" class="withdraw-loading"><van-loading type="spinner"/></div>
      </ul>

      <template v-else>
        <MyEmpty/>
      </template>
    </van-tab>
    <van-tab :title='$t("settle.settleOrder")'>
      <van-list
        v-if="settleAccountList.length !== 0"        
        v-model="isSettleAccountLoading"              
        :finished="isSettleAccontFinished"
        :finished-text='$t("mine.nomore")'
        :immediate-check="true"
        :loading-text='$t("settle.loading")'
        @load="handleSettleAccountLoad"
        class="list"
        offset="40"
        >
        <template v-for="item in settleAccountList">
          <SettleCard :settle-item="item"/>
        </template>
      </van-list>
      <template v-else>
        <MyEmpty/>
      </template>
      <div v-show="settleAccountList.length === 0 && isSettleAccountLoading" ><van-loading type="spinner"/></div>
    </van-tab>
  </van-tabs>
</template>

<script>
import { verifyQuery} from "@network/api";
import SettleCard from '@/components/SettleCard.vue';
import MyEmpty from "@/components/MyEmpty.vue";
import loadMoreFromBottom from "@/mixin/loadMoreFromBottom";



export default {
  props: {
    getContentRef: Function,
  deliveryList: {
    type: Array,
    default: () => ([])
  }
  },
  mixins: [ loadMoreFromBottom ],
  components: {
    SettleCard,
    MyEmpty
  },
  data() {
    return {
      activeTabIndex: 0,
      contentRef: null,

      // 待提现订单
      withDrawList: [],
      withDrawPage: 0,
      withDrawTotalPage: 0,
      withDrawParams: {
        page: 1,
        size: 10,
        withdrawStatus: "W",
      },
      isWithDrawLoading: false,
      nomoreData:false,
    }
  },
  mounted() {
    if(this.getContentRef() && this.activeTabIndex === 0) {
      this.contentRef = this.getContentRef()
      this.contentRef.addEventListener("scroll", this.handleScroll, true);
    }
  },
  watch: {
    deliveryList(newVal) {
      this.withDrawList = newVal
    }
  },
  methods: {
    chooseList(recordNo) {
      this.$router.push({
        name: "recordDetail",
        params: { recordNo: recordNo,from:'home' },
      });
      localStorage.setItem("recordNo", recordNo);
    },
    beforeChange(index) {
      if(index === 0 ) {
        this.contentRef.addEventListener("scroll", this.handleScroll, true);
      } else if(index === 1) {
        this.contentRef.removeEventListener("scroll", this.handleScroll, true);
      }
      this.activeTabIndex = index
      console.log('activeTabIndex', this.activeTabIndex,'index', index)
      return true
    },
    handleScroll() {
      //下面这部分兼容手机端和PC端
      var scrollTop = this.contentRef.scrollTop; //滚动条的位置
      var windowHeitht = this.contentRef.clientHeight; //在页面上返回内容的可视高度
      var scrollHeight = this.contentRef.scrollHeight; //返回整个元素的高度（包括带滚动条的隐蔽的地方）
      //是否滚动到底部的判断
      if (Math.round(scrollTop) + windowHeitht == scrollHeight) {
        this.withDrawParams.page++;
        if (this.withDrawPage <= this.withDrawTotalPage) {
          this.isWithDrawLoading = true;
          verifyQuery(this.withDrawParams)
            .then((res) => {
              this.isWithDrawLoading = false;
                if (res.code === "10011") {
                    return;
                  }
              if (res.code == "000000") {
                res.data.records.forEach((item) => {
                  this.withDrawList.push(item);
                });
                this.withDrawTotalPage = res.data.pages;
                this.withDrawPage = res.data.page;
              } else {
                this.$toast(res.message);
              }
            })
            .catch((err) => {
              this.isWithDrawLoading = false;
              this.$toast(err.message);
            });
        } else {
          this.nomoreData = true
          return;
        }
      }
    },
  }
}
</script>

<style scoped lang="less">
.list {
  padding: 11px 24px 0;
  position: relative;
}

.list .item {
  border-bottom: 1px solid #d5d5d5;
  position: relative;
}

.code .number {
  color: #333;
  font-size: 17px;
  line-height: 32px;
  font-weight: bold;
}

.item p {
  color: #999;
  font-size: 12px;
  line-height: 17px;
  min-height: 17px;
  margin: 9px 0;
}
.money {
  color: #268eff;
  right: 0;
  position: absolute;
  font-size: 17px;
  line-height: 32px;
  font-weight: bold;
}

/deep/ .van-list .settle-order:first-child {
  margin-top: 9px;
}


/deep/ .van-tabs--line .van-tabs__wrap {
  height: 44px;
}

/deep/ .van-tabs__nav .van-tabs__nav--line .van-tab:first-child {
  margin-right: 20px;
}

/deep/ .van-tab {
  flex-basis: auto ;
  flex-grow: 0;
  font-size: 16px;
  margin-right: 20px;
}

/deep/ .van-tab--active  {
  color: #2DD070;
}

/deep/ .van-tabs__line {
  bottom: 1px;
  background-color: #2DD070;
}

/deep/ .van-tabs__nav--line {
  padding-bottom: 0;
  padding: 0 24px;
}

/deep/ .van-list__loading {
  position: relative;
}

/deep/ .van-list__loading .van-loading {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
}


</style>