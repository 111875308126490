<template>
<div>
   <ScanBox v-if="showScan" @success="handleScanSuccess" @quit="stopScan" />
  <div v-if="allShow">
    <van-loading type="spinner" v-if="loading" />
    <div id="top">
      <span class="title" @click="showConsole"> {{ $t("stringCode.title") }}</span>
    </div>
    <div id="content" ref="withdrawUl">
      <div style="padding: 0 20px 0">
        <div class="card">
          <div class="pid" @click="scanCode">
            <img
              src="assets/scan.png"
              style="width: 24px; height: 24px"
              class="pl-20 pr-20 pb-16"
            />
            <span class="fs-26">{{ $t("stringCode.Scan") }} </span>
          </div>
          <div class="pid" @click="inputCode">
            <img
              src="assets/input.png"
              style="width: 24px; height: 24px"
              class="pl-20 pr-20 pb-16"
            />
            <span class="fs-26">{{ $t("stringCode.Enter") }}</span>
          </div>
        </div>
      </div>
     <div class="worth">
        <div class="worth-left">
          <p >{{ $t("stringCode.ForWithdrawalRewards") }}</p>
          <p class="bold">{{ totalRewards }}</p>
        </div> 
        <div class="worth-right">
          <button class="worth-btn" @click="openWorth" :disabled="!totalRewardsBtnShow "  >{{ $t("stringCode.Withdraw") }}</button>
        </div> 
      </div>  
      
      <MyTabs :get-content-ref="getContentRef" :delivery-list="withDrawList"/>

    </div>
    <van-tabbar v-model="active" active-color="#07c160" inactive-color="#999">
      <van-tabbar-item  to="/home">
        <span>{{ $t("navbar.home") }}</span>
        <template #icon="props">
          <img
            :src="props.active ? 'assets/homeActive.png' : 'assets/home.png'"
          />
        </template>
      </van-tabbar-item>
      <van-tabbar-item  to="/message">
        <span>{{ $t("navbar.message") }}</span>
        <template #icon="props2">
          <img
            :src="
              props2.active ? 'assets/messageActive.png' : 'assets/message.png'
            "
          />
        </template>
      </van-tabbar-item>
      <van-tabbar-item  to="/mine">
        <span>{{ $t("navbar.mine") }}</span>
        <template #icon="props3">
          <img
            :src="props3.active ? 'assets/mineActive.png' : 'assets/mine.png'"
          />
        </template>
      </van-tabbar-item>
    </van-tabbar>
        <!-- //弹框改为底部弹出 -->
    <van-popup v-model="stringBoxshow"  :round="true" :close-on-click-overlay='false' position="bottom" :style="{ height: isImei?'68%':'40%' }" >
      <div>
        <div class="stringBoxshow_title">
           <p class="dialogTitle stringBoxshow_title_left">{{ $t("stringCode.Tips") }}</p>
           <div class="stringBoxshow_title_right"><van-icon :size='20' name="cross" @click="closeStringBox"  /></div>
        </div>
        <div class="stringBoxshow_centen">
          <div class="stringBoxshow_centen_label">{{ $t("stringCode.imei") }}</div>
          <van-field v-model="stringCodeNumber" type="number" clearable />
          <div class="text">{{ $t("stringCode.content") }}</div>
           <!-- //销售凭证的上传图片组件 -->
          <div v-if="isImei">
            <div class="stringBoxshow_centen_label">{{ $t("stringCode.iMEIsalesVoucher") }}</div>
                <div>
                  <van-uploader class="stringBoxshow_centen_uploader" @oversize="onOversize" @delete="deleteClzp" :before-read="beforeRead" :after-read="clzpAfterRead" v-model="fileList"
                   :max-count="1" :max-size="16 * 1024 * 1024">
                    <div class="stringBoxshow_centen_uploader_input">
                      <div><van-icon name="plus" :size='20' /> <div class="stringBoxshow_centen_uploader_text">{{ $t("stringCode.iMEIsalesPhoto") }}</div></div>
                    </div>
                </van-uploader>
                </div>
          </div>
          <div class="stringBoxshow_bootom">
            <van-button type="primary" class="stringBoxshow_btn" @click="submitCode" :disabled="loading">  {{ $t("stringCode.Done") }}</van-button>
          </div>
          
        </div>
      </div>
    </van-popup>

    <div class="mengceng" v-show="mengcengshow"></div>
    <!-- <div class="stringBox" v-show="stringBoxshow">
      <p class="dialogTitle">{{ $t("stringCode.Tips") }}</p>
      <p class="text">{{ $t("stringCode.content") }}</p>
      <van-field v-model="stringCodeNumber" type="number" clearable />
      <div class="btnDiv">
        <div class="textcancelbtn" @click="closeStringBox">
          {{ $t("stringCode.Cancel") }}
        </div>
        <button class="textbtn" @click="submitCode" :disabled="loading">
          {{ $t("stringCode.Done") }}
        </button>
      </div>
    </div> -->
    <div class="withdrawFailBox" v-show="withdrawFailBoxshow">
      <p>{{ failTitle }}</p>
      <p>{{ failReason }}</p>
      <p class="textbtn" @click="closeWithdrawFailBox">
        {{ $t("stringCode.Done") }}
      </p>
    </div>
    <!-- 基础奖励 -->
    <div class="withdrawBox" v-show="withdrawBoxshow">
      <div class="cont">
        <p >{{ $t("stringCode.Congratulations") }}</p>
        <p class="mes">
          {{ $t("stringCode.sale1") }}
            <span>{{ skuName }}</span>
          {{ $t("stringCode.sale2") }}
        </p>
        <p style="margin: 10px 0 0px 0">{{ $t("stringCode.Rewards") }}</p>
        <p style="margin-bottom: 15px">{{ points }}</p>
        <!-- <div class="btn" @click="Withdraw" :disabled="!withdrawBtnShow">{{ $t("stringCode.Withdraw") }}</div> -->
        <button  class="btn" @click="Withdraw" :disabled="!withdrawBtnShow">{{ $t("stringCode.Withdraw") }}</button>
        <div class="cancelBtn" @click="cancleWithdraw">
          {{ $t("stringCode.Cancel") }}
        </div>
      </div>
    </div>
<!-- 阶梯核销  -->
    <div class="stepWithdrawBox" v-show="stepWithdrawBoxshow">
      <span class="closeIcon"   @click="cancleStepWithdraw">x</span>
      <div class="cont">
        <!-- 无奖励 -->
        <p class="conttitle" v-show="detailRsp =='NONE_AWARD'">{{ $t("stringCode.sorry") }}</p>

        <!-- 有奖励 -->
        <p  class="conttitle" v-show="detailRsp !=='NONE_AWARD'">{{ $t("stringCode.Congratulations") }}</p>
        <!-- 公用 -->
        <p class="mes" v-show="awardType !=='BASIC'">
          {{ $t("stringCode.sale1") }}
            <span>{{ skuName }}</span>
          {{ $t("stringCode.sale2") }}
        </p>
        <!-- 无奖励 -->
        <p class="mes" v-show="detailRsp =='NONE_AWARD'">
          {{ $t("stringCode.saleMessage1") }} 
          <span>{{ totalCount}}</span>
          {{ $t("stringCode.saleMessage2") }}
        </p>

       <!-- 有奖励 -->
        <p  class="mes" v-show="detailRsp !=='NONE_AWARD'"  style="margin: 10px 0 0px 0">{{ $t("stringCode.Rewards") }}</p>
        <p class= 'award' v-show="detailRsp !=='NONE_AWARD'"  style="margin-bottom: 15px">{{ points }}</p>


        <!-- 有追加奖励  ACROSS_AWARD -->
        <p class="mes" v-show="detailRsp =='ACROSS_AWARD'&& ( language =='zh'|| language == 'es')"  style="margin-bottom: 15px">
            {{ $t("stringCode.extraMessage1") }} 
          <span>{{ saleCount}}</span>
          {{ $t("stringCode.extraMessage2") }} 
          <span>{{ saleAwardPoints}}</span>
          {{ $t("stringCode.extraMessage3") }}
          <span>{{ subtractCount}}</span>
          {{ $t("stringCode.extraMessage4") }} 
          <span>{{ extraAwardPoints}}</span> 
          {{ $t("stringCode.extraMessage5") }} 
          </p>
         <p class="mes" v-show="detailRsp =='ACROSS_AWARD'&& language =='en'"  style="margin-bottom: 15px">
            {{ $t("stringCode.extraMessage1") }} 
          <span>{{ saleAwardPoints}}</span>
          {{ $t("stringCode.extraMessage2") }} 
          <span>{{ saleCount}}</span>
          {{ $t("stringCode.extraMessage3") }}
          <span>{{ extraAwardPoints}}</span> 
          {{ $t("stringCode.extraMessage4") }} 
          </p>

          <!-- 已核销几台还差几台  只有 除了最大阶梯 和首次跨越最大阶梯 都显示 -->
        <p class="blackP"   v-show="detailRsp !== 'MAXIMUM_AWARD' && (language == 'zh'|| language == 'es') && !acrossLastStep "
        style="margin-bottom: 15px">
           {{ $t("stringCode.saleCountMessage1") }} 
          <span class="greenbold">{{ saleCount}}</span>
          {{ $t("stringCode.saleCountMessage2") }}
          <span class="greenbold">{{ needCount}}</span>
          {{ $t("stringCode.saleCountMessage3") }}
          <span class="greenbold">{{ awardPoints}}</span>
          {{ $t("stringCode.saleCountMessage4") }}
        </p>
        <p class="blackP"   v-show="detailRsp !== 'MAXIMUM_AWARD' && language == 'en'&& !acrossLastStep"
        style="margin-bottom: 15px">
          <span class="greenbold">{{ saleCount}}</span>
          {{ $t("stringCode.saleCountMessage1") }}
          <span class="greenbold">{{ awardPoints}}</span>
          {{ $t("stringCode.saleCountMessage2") }}
          <span class="greenbold">{{ needCount}}</span>
          {{ $t("stringCode.saleCountMessage3") }}
        </p>
        <!-- 首次达到最大阶梯 和 超过最大阶梯 -->
        <p class="blackP"   v-show="detailRsp == 'MAXIMUM_AWARD'|| acrossLastStep "
        style="margin-bottom: 15px">
           {{ $t("stringCode.maxsaleMessage1") }} 
          <span class="greenbold">{{ saleCount}}</span>
          {{ $t("stringCode.maxsaleMessage2") }}
        </p>
      <div class="progress">
        <div class="progressContent">
          <div class="green"  :style="{width: 100*(saleCount/lastStepCount) +'%'}"></div>
        </div>
         <ul class="progressWord" v-for="(item) in awardTierList" :key="item">
          <li class="step" :style="{left: 100*(item/lastStepCount) +'%'}">{{item}}</li>
        </ul> 
      </div>
        <p class="rule" v-show="awardType =='EXTRA'">{{ ruleDesc}}</p>

        <!-- <div class="btn" v-show="detailRsp !=='NONE_AWARD'" @click="Withdraw"  :disabled="!withdrawBtnShow">{{ $t("stringCode.Withdraw") }}</div> -->
        <button class="btn" v-show="detailRsp !=='NONE_AWARD'" @click="Withdraw"  :disabled="!withdrawBtnShow">{{ $t("stringCode.Withdraw") }}</button>
        <!-- <div class="btn" v-show="detailRsp =='NONE_AWARD'" @click="cancleStepWithdraw">{{ $t("stringCode.ikonw") }}</div> -->
        <button class="btn" v-show="detailRsp =='NONE_AWARD'" @click="cancleStepWithdraw">{{ $t("stringCode.ikonw") }}</button>
      </div>
    </div>


   <!-- 合并提现 -->
        <div class="withdrawBox" v-show="totalWithdrawBoxshow">
          <span class="closeIcon" @click="cancleTotalWithdraw">x</span>
      <div class="cont">
        <p class="fontnormal">{{ $t("withdraw.WithdrawalAmount") }}</p>
        <span class="rewards">{{ totalRewards }}</span>
        <p class="detail" style="text-align: left;    border-bottom: 1px solid #fafafa;    margin-top: 14px;">
          <span>{{ $t("stringCode.RewardsCount") }}</span>
          <span>{{ RewardsCount }} {{ $t("stringCode.items") }}</span>
        </p>
        <p class="detail">
          <span>{{ $t("withdraw.Withdrawnto") }}</span>
          <span>{{ bankAccount }}</span>
        </p>
        <!-- <div class="btn" style="margin: 14px;" @click="totalWithdraw" :disabled="loading">{{ $t("stringCode.Withdraw") }}</div> -->
        <button  class="btn" style="margin: 14px;" @click="totalWithdraw" :disabled="loading">{{ $t("stringCode.Withdraw") }}</button>
      </div>
    </div>

    <div class="cashoutFailBox" v-show="cashoutFailBoxshow">
      <p>{{ $t("withdraw.fail") }}</p>
      <p>{{cashoutFailReason}}</p>
      <div class="textbtn" @click="closecashoutFailBox">
        {{ $t("withdraw.Done") }}
      </div>
    </div>
 </div>
  </div>
</template>
<script>
import ScanBox from '@/components/ScanBox.vue';
import navBar from "@/components/navbar.vue"; //引入组件
import { verify, canWithdraw,preApply,batchWithdraw,salesDocumentConfigQuery} from "@network/api";
import { uploadFiles } from '@/network/api' //上传、删除接口
import { showVconsole } from '@/network/vconsole'
import { compressImg } from '@/network/compressImg'
import { base64ImgtoFile } from '@/network/utils'
import MyTabs from './components/MyTabs.vue';

let logCount = 0
export default {
  name: "",
  components: {
    navBar, ScanBox, MyTabs
  },
  watch: {
    $route: "getPath",
  },
  data() {
    return {
      allShow:true,
      showScan: false,
      nomoreData:false,
      active: 0,
      loading: false,
      msg: "",
      test: "",
      failTitle: "",
      failReason: "",
      // 合并提现失败原因
      cashoutFailReason:'',
      activityName: "",
      skuName: "",
      points: "",
      recordNo: "",
      list: [],
      stringBoxshow: false,
      stringCodeNumber: "",
      mengcengshow: false,
      withdrawBoxshow: false,
      // 阶梯核销
      stepWithdrawBoxshow: false,
      withdrawFailBoxshow: false,
      totalWithdrawBoxshow:false,


      totalnum: 0,
      pagNum: 0,
      params:{
        size: 10,
        page: 1,
        withdrawStatus: "W",
      },
      withDrawList: [],

      totalRewards:'0.00',
      language:'',
      RewardsCount:null,
      bankAccount:'',
      applyParams:{
        totalAmount:'',
        verifyRecordAppInfos:{}
      },
      awardType:'',  //基础奖励BASIC
                          // 阶梯奖励TIER :无奖励NONE_AWARD,阶梯内EXIST_AWARD, 超过最大阶梯MAXIMUM_AWARD   首次跨越阶梯ACROSS_AWARD
                          // 追加奖励EXTRA
           
      //  奖励具体类型
       detailRsp:'',
       ruleDesc:"", 
       points:null,  //null  销售金额
       awardTierList: [],          //奖励档位
      needCount:2,     //差值
      totalCount :4,      //下一个阶梯档位
      lastStepCount:null,         // 阶梯最大值 也就是总数
      awardPoints:800,      //奖励金额
       extraAwardPoints:null,  //追加奖励金额
      saleCount:null, // 销售数
      saleAwardPoints:null,
      subtractCount:null,
      extraAwardPoints:null,
      cashoutFailBoxshow: false,
      failReason: "",
      totalRewardsBtnShow:false,
      acrossLastStep:false,
      // 首次跨越最大阶梯
      withdrawBtnShow:false,
      fileList: [], //上传的文件列表
      clzpfilePath: "", //删除所需参数
      stringCodePath:'',
      isImei:false,
    };
  },
  mounted() {
    this.language = window.localStorage.getItem('languageSet');
    this.active = 0;
    this.loading = true;
    var that = this ;
      window.addEventListener('hashchange', function(e) {  
        const fromFullPath = e.oldURL.split('#')[1];
          if(fromFullPath.includes('home')){
            that.$router.replace("/home");
            location.reload();
            // 清空router
          }
      }, false);
    this.getPath();
    this.getAPPDate();
    this.getTotalAmount();
    // 调用子组件的方法，获取第一次返回的结算订单数
    // this.$refs.tabRef.fetchSettleAccountData()
  },
  methods: {
    closecashoutFailBox() {
      this.cashoutFailBoxshow = false;
      this.mengcengshow = false;
    },
    totalWithdraw(){
    this.loading = true;
    this.applyParams.totalAmount = this.totalRewards;
      batchWithdraw(this.applyParams)
        .then((res) => {
          this.loading = false;
          if(res.code === '10011'){
            return
          }
          if (res.code == "000000") {
            if (res.data.status == "F") {
              this.cashoutFailReason = res.data.statusDesc;
              this.cashoutFailBoxshow = true;
              this.totalWithdrawBoxshow = false;
            } else {
              this.$router.push({
                name: "withdrawResult",
                params: {
                  amount: res.data.amount,
                  bankAccount: res.data.bankAccount,
                  status: res.data.status,
                  createTime: res.data.createTime,
                  successTime: res.data.successTime,
                  fromPath: 'home'
                  // 传入 上一个页面
                },
              });
            }
          } else {
            this.$toast(res.message);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$toast(err.message);
        });
    },
    getPath() {
      if (this.$route.path == "/home") {
        this.active = 0;
      }
    },
    getTotalAmount(){
       canWithdraw({})
        .then((res) => {
          this.loading = false;
          if (res.code === "10011") {
            return;
          }
          if (res.code == "000000") {
            this.totalRewards   = res.data.totalAmount;
            this.RewardsCount  = res.data.batchSize;
             this.applyParams.verifyRecordAppInfos = res.data.verifyRecordAppRspList;
             this.withDrawList = res.data.verifyRecordAppRspList;
            if(Number(this.totalRewards)>0){
              this.totalRewardsBtnShow = true;
            }else{
              this.totalRewardsBtnShow = false;
            }
            
          } else {
            this.$toast(res.message);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$toast(err.message);
        });
      preApply({})
          .then((res) => {
            this.loading = false;
            if(res.code === '10011'){
            return
          }
            if (res.code == "000000") {
              this.bankAccount = res.data.bankAccount;
            } else {
              this.$toast(res.message);
            }
          })
          .catch((err) => {
            this.loading = false;
            this.$toast(err.message);
          });
    },
    chooseList(recordNo) {
      this.$router.push({
        name: "recordDetail",
        params: { recordNo: recordNo,from:'home' },
      });
      localStorage.setItem("recordNo", recordNo);
    },
    scanCode() {
      console.log('navigator.userAgent',navigator.userAgent);
      this.stringCodePath=""
        // let isAndroid = navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1;
        // let isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        // this.getSalesDocumentConfigQuery()
        let isApp = navigator.userAgent.indexOf('LABOR_UP') > -1 ;
         if(isApp){
           console.log('app');
          //  支持安卓原生扫码
            var that = this;
            this.$bridge.callHandler(
              "scanQrCode", // 与原生约定的方法名
              { msg: "扫码" }, // 传给原生的方法
              function (responseData) {
                console.log('responseData',responseData);
                // 原生返回值
                // that.stringBoxshow = true;
                that.mengcengshow = true;
                that.stringCodeNumber = responseData;
                that.fileList=[]
                that.getSalesDocumentConfigQuery()   //扫码成功后 再弹出框
              }
            );
         }else{
           console.log('else app');
          //  ios 扫码
           this.showScan = true
           this.allShow = false
         }
  
    },
    stopScan() {
      this.showScan = false
      this.allShow = true
      this.stringBoxshow=false
      this.fileList=[]
    },
    handleScanSuccess(code) {
     
      this.showScan = false
           this.allShow = true
      // this.stringBoxshow = true;
      this.fileList=[]
      this.mengcengshow = true;
      this.stringCodeNumber = code;
      this.getSalesDocumentConfigQuery()

    },

    sendMsg() {
      let msg = this.msg;
      this.$bridge.callHandler("dataToAndroid", msg, (res) => {
        alert("获取app响应数据:" + res);
        this.test = res;
      });
    },

    getAPPDate() {
      this.$bridge.registerHandler("dataToJs", (data, responseCallback) => {
        alert("app主动调用js方法，传入数据:" + data);
        responseCallback(data);
      });
    },
    toast(msg) {
      this.$bridge.callHandler(
        "toast", // 与原生约定的方法名
        msg, // 传给原生的方法
        function (responseData) {
          // 原生返回值
          console.log("return->" + responseData);
        }
      );
    },

    inputCode() {
      // this.stringBoxshow = true;
      this.getSalesDocumentConfigQuery()
      this.mengcengshow = true;
      this.fileList=[]   //清空图片
      this.stringCodePath=""
    },
    closeStringBox() {
      this.stringBoxshow = false;
      this.mengcengshow = false;
      this.stringCodeNumber = "";
      this.loading = false;
    },
    openWorth(){
      this.mengcengshow = true;
     this.totalWithdrawBoxshow = true;
    },
    submitCode() {
      // onsole.log(this.stringCodeNumber,this.stringCodePath,'stringCodePathstringCodePath');
      //根据权限配置判断 要不要验证 销售凭证
      if(this.isImei){
        if(this.stringCodeNumber == ""){
            this.loading = false;
            this.$toast(this.$t('stringCode.pleaseEnter'));
            return
        }
        if(this.stringCodePath == ""){
          this.loading = false;
          this.$toast(this.$t('stringCode.pleaseEnterPath'));
          return
        }
      }

      if (this.stringCodeNumber !== "") {
        this.failReason = "";
        this.failTitle = "";
        this.loading = true;
        let params= {
            code: this.stringCodeNumber
        }
        if(this.isImei){
          params.filePath=this.stringCodePath 
        }

        verify(params)
          .then((res) => {
            this.loading = false;
            if (res.code === "10011") {
              return;
            }
            if (res.code == "000000") {
              // 核销状态4种
              // REJECTED  核销驳回  ，ACTIVATED_FAIL  核销失败  WAIT_ACTIVE  待激活
              // 成功情况 ： 待审核（待确认） UNAUDITED  ， 核销成功   ACTIVATED
              if (res.data.verifyStatus == "ACTIVATED") {
                this.stringBoxshow = false;
                this.skuName = res.data.skuName;
                this.recordNo = res.data.recordNo;
                this.activityName = res.data.activityName;
                this.stringCodeNumber = "";
                this.ruleDesc = res.data.ruleDesc;
                this.awardType =res.data.awardType;
                 this.points = res.data.detailRsp.points;

                  if(Number(this.points)>0){
                    this.withdrawBtnShow = true;
                  }else{
                    this.withdrawBtnShow = false;
                  }
                 
                // 基础奖励
                if(res.data.awardType == "BASIC"){
                this.withdrawBoxshow = true;
                }else if (res.data.awardType == "TIER"){
                // 阶梯奖励
                this.stepWithdrawBoxshow = true;
                //  奖励具体类型
                 this.detailRsp = res.data.detailRsp.tierType;
                //  奖励档位
                 this.awardTierList = res.data.detailRsp.awardTierList;
                 this.lastStepCount = this.awardTierList.slice(-1)[0];
                 console.log(this.lastStepCount);

                 this.tierList = res.data.detailRsp.tierList;
                 this.saleCount = res.data.detailRsp.saleCount;
                 this.needCount = res.data.detailRsp.needCount;
                 this.totalCount = res.data.detailRsp.totalCount;
                 this.awardPoints = res.data.detailRsp.awardPoints;
                //  阶梯无奖励
                    if(this.detailRsp == "NONE_AWARD"){
                         //  阶梯   阶梯内
                    }else if (this.detailRsp == "EXIST_AWARD"){
                          
                        //  阶梯 超过最大阶梯
                    }else if (this.detailRsp == "MAXIMUM_AWARD"){

                    }else if(this.detailRsp == "ACROSS_AWARD"){
                        if( this.saleCount ==this.lastStepCount){
                            this.acrossLastStep =true;
                        }
                    }
                }else if (res.data.awardType == "EXTRA"){
                // 追加奖励
                this.stepWithdrawBoxshow = true;
                //  奖励具体类型
                 this.detailRsp = res.data.detailRsp.tierType;
                 this.awardTierList = res.data.detailRsp.awardTierList;
                 this.lastStepCount = this.awardTierList.slice(-1)[0];
                 this.tierList = res.data.detailRsp.tierList;
                 this.saleCount = res.data.detailRsp.saleCount;
                 this.needCount = res.data.detailRsp.needCount;
                 this.totalCount = res.data.detailRsp.totalCount;
                 this.awardPoints = res.data.detailRsp.awardPoints;
                //  追加无奖励
                    if(this.detailRsp == "NONE_AWARD"){
                        // this.points = res.data.points;
                         //  追加   首次跨越阶梯
                    }else if (this.detailRsp == "ACROSS_AWARD"){
                       // 追加奖励计算  具体怎么计算
                        this.saleCount = res.data.detailRsp.extraAwardRsp.saleCount;
                        this.saleAwardPoints = res.data.detailRsp.extraAwardRsp.saleAwardPoints;
                        this.subtractCount = res.data.detailRsp.extraAwardRsp.subtractCount;
                        this.extraAwardPoints = res.data.detailRsp.extraAwardRsp.extraAwardPoints;

                        if( this.saleCount ==this.lastStepCount){
                                  this.acrossLastStep =true;
                              }
                           
                        //  追加 阶梯内
                    }else if (this.detailRsp == "EXIST_AWARD"){

                    }else if (this.detailRsp == "MAXIMUM_AWARD"){
                       //  追加 最大阶梯

                    }
                }
              } else if (res.data.verifyStatus == "ACTIVATED_FAIL") {
                this.failTitle = this.$t("stringCode.activatedfail");
                this.stringBoxshow = false;
                this.withdrawFailBoxshow = true;
                this.failReason = res.message;
                this.stringCodeNumber = "";
              } else if (res.data.verifyStatus == "REJECTED") {
                this.failTitle = this.$t("stringCode.REJECTED");
                this.stringBoxshow = false;
                this.withdrawFailBoxshow = true;
                this.failReason = res.message;
                this.stringCodeNumber = "";
              } else if (res.data.verifyStatus == "UNAUDITED") {
                this.failTitle = this.$t("stringCode.UNAUDITED");
                this.stringBoxshow = false;
                this.withdrawFailBoxshow = true;
                this.stringCodeNumber = "";
                // this.failReason = res.message;
              } else if (res.data.verifyStatus == "WAIT_ACTIVE") {
                this.failTitle = this.$t("stringCode.WAITACTIVE");
                this.stringBoxshow = false;
                this.withdrawFailBoxshow = true;
                this.stringCodeNumber = "";
                // this.failReason = res.message;
              }
              
            } else {
              this.stringBoxshow = false;
              this.withdrawFailBoxshow = true;
              this.failReason = res.message;
              this.stringCodeNumber = "";
            }
          })
          .catch((err) => {
            this.loading = false;
            this.$toast(err.message);
          });
      } else {
            this.loading = false;
        this.$toast(this.$t('stringCode.pleaseEnter'));
      }
    },
    cancleWithdraw() {
      this.withdrawBoxshow = false;
      this.mengcengshow = false;
      this.stringCodeNumber = "";
      this.getTotalAmount();
    },
    cancleStepWithdraw() {
      this.stepWithdrawBoxshow = false;
      this.mengcengshow = false;
      this.stringCodeNumber = "";
      this.getTotalAmount();
    },
    cancleTotalWithdraw(){
      this.totalWithdrawBoxshow = false;
      this.mengcengshow = false;
    },
    closeWithdrawFailBox() {
      this.withdrawFailBoxshow = false;
      this.mengcengshow = false;
    },
    Withdraw() {
      this.$router.push({
        name: "withdraw",
        params: {
          skuName: this.skuName,
          points: this.points,
          recordNo: this.recordNo,
          activityName: this.activityName,
        },
      });
    },
     //校验上传图片大小
    onOversize(file) {
      console.log(file,'校验上传图片大小');
      // this.$toast("文件大小不能超过5MB");
    },
    //校验图片的格式
    beforeRead(file) {
      if (!/(jpg|jpeg|png|JPG|PNG)/i.test(file.type)) {
        this.$toast("请上传正确格式的图片");
        return false;
      }
      return true;
    },
    //照片上传事件方法
    clzpAfterRead(file) {
      // 上传状态提示开启
      file.status = "uploading";
      this.loading=true
        console.log(file,'file');
        this.fileList.imgs = [] 
          let img = new Image()
          img.src = file.content
          img.onload = () => {
            let templateFile=base64ImgtoFile(compressImg(img)) //这个是base64转file的方法
            console.log(templateFile,'templateFile');
            try {
                this.UploadFile(templateFile,file)
              } catch (err) {
                this.loading = false;
                  this.$toast(err.message);
                  this.stringCodePath=""
                  file.status = "failed";
                  this.fileList=[]
              }
          }
    },
    //压缩完成之后 上传给后端
    UploadFile(templateFile,file){
      // 上传状态提示开启
      // file.message = "上传中...";
      // 创建一个空对象实例
      let formData = new FormData();
      // 调用append()方法添加数据
      formData.append("file", templateFile);
      console.log(formData,'formData');
      uploadFiles(formData).then((res) => {
        console.log(res);
        if (res&&res.code == "000000") {
          // 上传状态提示关闭
          file.status = "done";
          // this.$toast("上传成功！");
          this.loading=false
          this.stringCodePath=res.data
        }else{
          this.$toast(res.message);
        }
      }).catch((err) => {
          this.loading = false;
          this.$toast(err.message);
          this.stringCodePath=""
          this.fileList=[]
        });
    },
    //照片删除事件方法
    deleteClzp() {
      this.stringCodePath=""
      this.fileList=[]
    },
    //获取销售凭证的配置
    getSalesDocumentConfigQuery(){
      this.loading=true
        salesDocumentConfigQuery({}).then((res) => {
            console.log(res,'获取销售凭证的配置');
            if(res&&res.code=='000000'){
              this.loading=false
              this.isImei=res.data.config=='close'?false:true
              this.stringBoxshow = true;
              this.mengceng=true
              // this.isImei=true
            }else{
              this.loading = false;
             this.$toast(res.message);
            }
            
        }).catch((err) => {
          this.loading = false;
          this.$toast(err.message);
        });
    },
    //打开 Vconsole
    showConsole() {
      if (logCount < 10) {
        logCount++
        return
      }

      showVconsole()
    },
    // 传递给子组件获取dom，监听滚动
    getContentRef() {
      return this.$refs.withdrawUl
    }
  },
};
</script>
<style scoped>
#content{
  padding-bottom: 0;
}
.card {
  color: #666;
  padding: 17px 0;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  background: #fff;
  text-align: center;
}
.pid {
  display: flex;
  /* flex-direction: column; */
  margin-right: 84rpx;
  width: calc(50vw - 30px);
  justify-content: center;
  align-items: center;
  height: 96px;
  left: 24px;
  margin-right: 12px;
  top: 104px;
  background: #2dd070;
  border-radius: 12px;
}
.pid:nth-child(2) {
  display: flex;
  /* flex-direction: column; */
  margin-right: 84rpx;
  width: calc(50vw - 29px);
  justify-content: center;
  align-items: center;
  height: 96px;
  left: 24px;
  top: 104px;
  background: #268eff;
  margin-right: 0;
  border-radius: 12px;
}
.pid img {
  width: 24px;
}
.pid span {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  padding-left: 10px;
}

.selectedColor {
  color: #2dd070;
}
.tabs-wrapper {
  background: #fff;
  border-radius: 10px;
}
/* .toWithDraw > div {
  border-bottom: 1px solid rgb(153, 153, 153);
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
} */
.tab {
  display: inline-block;
  height: 44px;
  color: #333;
  line-height: 44px;
  font-weight: bold;
  font-size: 16px;
  line-height: 44px;
  padding-left: 24px;
}

.code .number {
  color: #333;
  font-size: 17px;
  line-height: 32px;
  font-weight: bold;
}

.item p {
  color: #999;
  font-size: 12px;
  line-height: 17px;
  min-height: 17px;
  margin: 9px 0;
}
.money {
  color: #268eff;
  right: 0;
  position: absolute;
  font-size: 17px;
  line-height: 32px;
  font-weight: bold;
}
.mengceng {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0.3;
  background: #333;
}
.stringBox {
  box-sizing: border-box;
  position: fixed;
  top: calc(50vh - 140px);
  left: 8%;
  width: 280px;
  padding: 24px;
  background: #fff;
  height: 220px;
  border-radius: 16px;
  width: 84%;
  min-height: 257px;
  height: auto;
}
.dialogTitle {
  text-align: center;
  margin: 0;
  font-weight: bold;
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
}
.dialogTitle span {
  position: absolute;
  right: 0;
  font-weight: normal;
}
.text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #999999;
  margin: 15px 0 26px 0;
}
.van-cell {
  padding: 10px 16px;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background: #fafafa;
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 12px;
}

.withdrawBox {
  box-sizing: border-box;
  position: fixed;
  top: calc(50vh - 140px);
  left: 8%;
  width: 280px;
  padding: 24px;
  background: #fff;
  height: auto;
  border-radius: 16px;
  width: 84%;
}
.withdrawBox .cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin-top: 7px;
}
.withdrawBox .cont p {
  margin: 0;
}
.withdrawBox .cont > p:nth-child(1) {
  color: #333;
  font-size: 16px;
  line-height: 22px;
}
.withdrawBox .cont > p:nth-child(2),
.withdrawBox .cont > p:nth-child(3) {
  color: #999;
  font-size: 13px;
  text-align: center;
  line-height: 22px;
  margin-top: 7px;
}
.withdrawBox .cont > p:nth-child(4) {
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 32px;
  line-height: 44px;
  color: #333;
}

.stepWithdrawBox {
  box-sizing: border-box;
  position: fixed;
  top: calc(50vh - 205px);
  left: 8%;
  width: 280px;
  padding: 6%;
  background: #fff;
  height: auto;
  border-radius: 16px;
  width: 84%;
}
.stepWithdrawBox .cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
}

.bold{
  font-weight: bold;
}
.greenbold{
  font-weight: bold;
  color: #2dd070;
}

.mes{
   text-align: center;
 color: #999;
    font-size: 13px;
    line-height: 20px;
    margin: 4px 0;
}
.stepWithdrawBox .mes{
 text-align: center;
 color: #999;
    font-size: 13px;
    line-height: 20px;
    margin: 4px 0;
}
.stepWithdrawBox .mes span{
  text-align: center;
 color: #999;
    font-size: 13px;
    line-height: 20px;
}
.stepWithdrawBox .blackP{
text-align: center;
 color: #333;
    font-size: 13px;
    line-height: 20px;
    margin: 10px 0 0 0 ;
}
.conttitle{
font-weight: bold;
color: #333333!important;
font-size: 16px!important;
line-height: 22px!important;
margin-bottom: 8px;
}
.btn {
  line-height: 44px;
  color: #fff;
  text-align: center;
  width: 220px;
  height: 44px;
  left: 70px;
  font-size: 14px;
  top: 451px;
  background: #2dd070;
  border-radius: 12px;
}
.cancelbtn {
  width: 220px;
  height: 44px;
  border-radius: 5px;
  line-height: 44px;
  text-align: center;
  background: #fff;
  margin-top: 10px;
  color: #2dd070;
  font-size: 14px;
  margin-top: 16px;
}
.cancelBtn {
  width: 150px;
  height: 40px;
  border-radius: 5px;
  line-height: 40px;
  color: #2dd070;
  text-align: center;
  background: #fff;
  margin-top: 10px;
  font-size: 14px;
}
.btnDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 34px 0 34px;
}

.textcancelbtn {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #333333;
}
.withdrawFailBox {
  box-sizing: border-box;
  position: fixed;
  top: calc(50vh - 140px);
  left: 8%;
  width: 280px;
  padding: 24px;
  background: #fff;
  height: auto;
  border-radius: 16px;
  width: 84%;
  text-align: center;
}
.withdrawFailBox p:nth-child(1) {
  margin: 0;
  margin-bottom: 20px;
  color: #333;
  font-size: 16px;
  line-height: 22px;
}
.withdrawFailBox p:nth-child(2) {
  margin-bottom: 20px;
  /* font-weight: bold; */
  font-size: 13px;
  line-height: 18px;
  color: #999;
}
.textbtn {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #2dc84e;
}
.worth{
margin: 0px 20px;
    height: 80px;
    border: 1px solid #d5d5d5;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    box-sizing: border-box;
}
.worth-left{
flex: 1;
}
 .worth-left p{
color: #999;
    font-size: 12px;
    line-height: 34px;
}
.worth-left .bold{
   color: #333;
    font-size: 17px;
    line-height: 22px;
    font-weight: bold;
} 
.worth-right{
  height: 38px;
}
.worth-right .worth-btn{
 width: auto;
    line-height: 38px;
    height: 38px;
    background:#fafafa ;
    display: inline-flex;
    color: #2dd070;
     padding: 0 14px;
    font-size: 13px;
    border-radius: 8px;
}
.closeIcon{
position: absolute;
    right: 20px;
    top: 10px;
}
.detail{
      margin: 0;
    line-height: 22px!important;
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}
.detail span{
color: #999;
    font-size: 13px;
    line-height: 22px;
    margin-top: 7px;
        display: inline-block;
    width: 135px;
}
.detail > span:nth-child(2) {
    text-align: right;
    flex: 1;
}
.fontnormal{
      color: #999!important;
    font-size: 13px!important;
    line-height: 22px!important;
    margin-bottom: 10px!important;
}
.rewards{
        font-weight: bold;
    font-size: 24px;
    line-height: 34px;
    color: #333333;
}
.rule{
  width: 100%;
   color: #999;
    font-size: 11px;
    line-height: 20px;
      margin-bottom: 21px;
   text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.progress{
   width:calc(100vw - 120px);
    margin-bottom: 10px;
    position: relative;
    height: 35px;
}
.progressContent{
     width:calc(100vw - 120px);
    height: 12px;
    position: relative;
    background: #EFF0F2;
    border-radius: 5px;
}
.progressContent .green{
    background: #2DD070;
    height: 12px;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 5px;
    max-width: 100%;
}
.progressWord{
 position: absolute;
    left:-7px;
    top: 18px;
    font-size: 11px;
    height: 18px;
    line-height: 15px;
     width:calc(100vw - 120px);
    color: #999999;
}

.progressWord .step{
  position: absolute;
    left: 8px;
}
.award{
      margin-bottom: 15px;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
}
.cashoutFailBox {
  box-sizing: border-box;
  position: fixed;
  top: calc(50vh - 74px);
  left: 8%;
  width: 280px;
  padding: 24px;
  background: #fff;
  height: auto;
  border-radius: 16px;
  text-align: center;
  width: 84%;
}
.cashoutFailBox p:nth-child(1) {
  margin: 0;
  margin-bottom: 20px;
  color: #333;
  font-size: 16px;
  line-height: 22px;
}
.cashoutFailBox p:nth-child(2) {
  margin-bottom: 20px;
  /* font-weight: bold; */
  font-size: 13px;
  line-height: 18px;
  color: #999;
}
.cashoutFailBox .textbtn {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #2dc84e;
}
.btn:disabled {
  opacity: 0.35;
}
.stringBoxshow_title {display: flex; height: 48px;line-height: 48px;}
.stringBoxshow_title_left {flex: 1; font-size: 16px; color: #333333; font-weight: 400;line-height: 48px;}
.stringBoxshow_title_right {padding-right: 10px;padding-top: 4px; color: #999;}
.stringBoxshow_centen {
  padding-left: 24px;
  padding-right: 24px;
}
.stringBoxshow_centen_label {
  font-weight: 400;
  font-size: 16px;
  color:#333;
  padding-bottom: 12px;
}
.stringBoxshow_centen_uploader {
  width: 100%;
  height: 120px;
  text-align: center;
}

.stringBoxshow_centen_uploader_input {
  width:100%;
  height: 100%;
  background: #FAFAFA;
  display: flex;
  align-items: center;
  justify-content: center;
}
.stringBoxshow_centen_uploader_input /deep/ .van-icon-plus {
  color: #268EFF;
}
.stringBoxshow_centen_uploader /deep/ .van-uploader__wrapper {
  width: 100% !important;
  height: 100% !important;
}
.stringBoxshow_centen_uploader /deep/  .van-uploader__input-wrapper {
  width: 100% !important;
}
.stringBoxshow_centen_uploader /deep/  .van-uploader__preview-delete {
  width: 20px !important;
  height: 20px !important;
}
.stringBoxshow_centen_uploader /deep/  .van-uploader__preview-delete-icon {
  transform: scale(1) !important ;
  top:1px;
  right:0px;
}


.stringBoxshow_centen_uploader_text {
  font-size: 13px;
  color: #268EFF;
}
.stringBoxshow_centen_uploader /deep/  .van-uploader__preview {
   width: 100% !important;
}
.stringBoxshow_centen_uploader /deep/  .van-uploader__preview-image {
   width: 100% !important;
   height: 136px;
}
.stringBoxshow_bootom {
  width: 100%;
  margin-top: 30px;
}
.stringBoxshow_btn {
  width: 100%;
  border-radius: 6px;
}

.van-tabs {
  margin-top: 30px;
}


</style>
<style lang="less" scoped>
.list {
  padding: 11px 24px 0;
  position: relative;
}

.list .item {
  border-bottom: 1px solid #d5d5d5;
  position: relative;
}

/deep/ .van-list .settle-order:first-child {
  margin-top: 9px;
}

.tabs-wrapper {
  /deep/ .van-tabs--line .van-tabs__wrap {
    height: 44px;
  }
  
  /deep/ .van-tab {
    flex-basis: 100px ;
    flex-grow: 0;
    font-size: 16px;
  }
  
  /deep/ .van-tab--active  {
    color: #2DD070;
  }
  
  /deep/ .van-tabs__line {
    bottom: 1px;
    // background-color: #2DD070;
  }
  
  /deep/ .van-tabs__nav--line {
    padding-bottom: 0;
  }
  
  /deep/ .van-list__loading {
    position: relative;
  }
  
  /deep/ .van-list__loading .van-loading {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
  }

}

.settle-order {
  border-radius: 10px;
  box-shadow: 0px 10px 15px -3px #C4C4C4,11px 13px 15px -6px #C4C4C4;

  &:first {
    margin-top: 9px;
  }

  .settle-item-top {
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    border-radius: 10px 10px 0 0;
    background-color: rgb(247, 247, 248);
    color: rgb(51, 51, 51);
  }

  .settle-item-bottom {
    display: flex;
    height: 80px;
    padding: 0 20px;
    color: rgb(102, 102, 102);
    margin-bottom: 20px;
    font-size: 13px;
    justify-content: space-between;
    align-items: center;

    .settle-item-count, .settle-item-amount, .settle-item-icon {
      display: flex;
      // height: 50px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .settle-item-num {
      margin-top: 5px;
      font-size: 24px;
      color: #333333;
    }

    .settle-item-icon {

      .settle-item-container {
        width: 24px;
        height: 24px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .settle-item-text {
        margin-top: 5px;
        color: rgb(153, 153, 153);
      }
    }
  }
}


.nodata-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 230px;
  
  .nodata {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 88px;


    .img-container {
      width: 96px;
      height: 62px;

      img {
        width: 100%;
        height: 100%;
      }
    }
    
    .nodata-desc {
      color: #CCCCCC;
      height: 16px;
      line-height: 16px;
      font-size: 12px;
    }
  }
}

</style>