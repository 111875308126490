import { render, staticRenderFns } from "./ScanBox.vue?vue&type=template&id=18fe5ab3&scoped=true&"
import script from "./ScanBox.vue?vue&type=script&lang=js&"
export * from "./ScanBox.vue?vue&type=script&lang=js&"
import style0 from "./ScanBox.vue?vue&type=style&index=0&id=18fe5ab3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18fe5ab3",
  null
  
)

export default component.exports